import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PATH } from "utils/const";
import BufferPage from "pages/buffer";
import SellerHub from "pages/SellerHub";
import HoangYenMiddlePage from "pages/HoangYenMiddlePage";

const routes = [
  {
    path: `${PATH}/store/:storeCode`,
    component: <BufferPage />,
  },
  {
    path: `${PATH}/reservation/:storeCode`,
    component: <BufferPage />,
  },
  {
    path: `${PATH}/landing`,
    component: <BufferPage />,
  },
  {
    path: `${PATH}/seller-hub`,
    component: <SellerHub />,
  },
  {
    path: `${PATH}/hoang-yen`,
    component: <HoangYenMiddlePage />,
  },
];

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={item.component}
              exact
            />
          ))}
          <Route path="*" element={<BufferPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default App;
