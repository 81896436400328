import React from 'react'
import style from './style.module.scss'

const Step = ({ children, data = {} }) => {
  return (
    <div className='flex flex-col gap-4 px-4 py-3 rounded-lg shadow-[0px_2px_12px_0px_rgba(0,31,62,0.05)]'>
      <div className='w-full flex items-start gap-4'>
        {data.logo && (<div className='w-[48px] h-[48px]'><img className='w-full object-cover' src={data.logo} alt="Ảnh" /></div>)}
        <div className={`flex flex-col gap-1 items-start ${data.logo ? style['step-with-logo'] : 'w-full'}`}>
          {data.count && (<p className='font-bold text-xs text-[#02A25F]'>{data.count}</p>)}
          <p className='font-bold text-sm leading-[18px]'>{data.title ?? ''}</p>
          <p className='text-sm leading-[18px] text-[#66798B]'>{data.description ?? ''}</p>
        </div>
      </div>
      {children && (
        <div className='w-full'>
          {children}
        </div>
      )}
    </div>
  )
}

export default Step