import React from "react";
import QRCode from "react-qr-code";
import logoZl from "assets/images/landing/logo-zl.png";
import logoZlp from "assets/images/landing/logo-zlp.png";
import './style.scss'

const PCBody = ({redirectURL, appType}) => {
  const TitleMap = {
    Reservation: "Mở Zalo quét mã để đặt chỗ ngay",
    OSC: "Mở Zalo quét mã để mua hàng ngay"
  }


  const renderLogos = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ margin: '15px', padding: '15px', background: '#F2F4F5', display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logoZl} style={{ height: '58px' }} alt={logoZl} />
        </div>
        <div style={{ margin: '15px', padding: '15px', background: '#F2F4F5', display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logoZlp} style={{ height: '58px' }} alt={logoZlp} />
        </div>
     </div>
    )
  }
  const renderTitle = (title) => {
    return (
      <div> 
        <p style={{ margin: '5px', fontWeight: 600, fontSize: 32, lineHeight: '38px' }}> {title} </p>
      </div>
    )
  }
  const renderQRCOde = (redirectURL) => {
    return (
      <div style={{ background: '#FFFFFF', display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <div className="qrBorder">
          <QRCode size={200} value={redirectURL} />
        </div>
      </div>
    )
  }
  const renderOSCGuide = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px', flexDirection: 'column' }}>
        <div >
          <p style={{ fontWeight: 'bold', lineHeight: '44px' }}>Hướng dẫn quét mã mua hàng trên Zalo:</p>
          <p style={{ fontSize: '14px', lineHeight: '22px' }}>Bước 1: Vào Ứng dụng Zalo trên điện thoại <br />
            Bước 2: Tìm icon Quét mã {"->"} <strong>Quét mã trên màn hình</strong><br />
            Bước 3: Vào cửa hàng, chọn sản phẩm cần mua và thanh toán</p>
        </div>
      </div>
    )
  }
  const renderReservationGuide = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px', flexDirection: 'column' }}>
        <div >
          <p style={{ fontWeight: 'bold', lineHeight: '44px' }}>Hướng dẫn quét mã đặt chỗ trên Zalo:</p>
          <p style={{ fontSize: '14px', lineHeight: '22px' }}>Bước 1: Vào Ứng dụng Zalo trên điện thoại <br />
            Bước 2: Tìm icon Quét mã {"->"} <strong>Quét mã trên màn hình</strong><br />
            Bước 3: Vào cửa hàng, chọn chi nhánh đặt chỗ<br />
            Bước 4: Lựa chọn ngày, khung giờ, số lượng người và thanh toán 
          </p>
        </div>
      </div>
    )
  }
  const renderGuide = () => {
    switch(true) {
      case appType === "Reservation":
        return renderReservationGuide();
      case appType === "OSC":
        return renderOSCGuide();
      default:
        return null;
    }
  }
  
  return (
    <div className={`landing-page view-desktop`}>
      <div style={{ padding: '20px 60px', background: '#FFFFFF', display: 'flex', borderRadius: '8px', flexDirection: 'column' }}>
        {renderLogos()}
        {renderTitle(TitleMap[appType] || "")}
        {renderQRCOde(redirectURL)}
        {renderGuide()}
      </div>
    </div >
  )
}

export default PCBody