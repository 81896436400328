export const PATH = process.env.PUBLIC_URL;

export const BENEFIT_SECTION = {
  title: "Lợi ích khi mở cửa hàng với ZaloPay",
  list: [
    {
      image: "",
      title: "Tư vấn trực tiếp trên Zalo",
      content:
        "Tư vấn, tương tác trực tiếp với khách hàng trên nền tảng Zalo, nâng cao trải nghiệm shopping",
    },
    {
      image: "",
      title: "Gian hàng tích hợp",
      content:
        "Giúp người mua tìm hiểu, đặt hàng dễ dàng, thuận lợi ngay từ khung chat",
    },
    {
      image: "",
      title: "Xây dựng thương hiệu",
      content:
        "Xây dựng thương hiệu cửa hàng dễ dàng trên nền tảng Zalo hàng triệu người dùng",
    },
    {
      image: "",
      title: "Hoàn toàn miễn phí",
      content: "Không thu phí bán hàng trong quá trình hoạt động của cửa hàng",
    },
  ],
  button: "Đăng ký bán hàng trên ZaloPay ngay",
};

export const QUESTIONS = [
  {
    type: 3,
    title:
      "Để bán hàng trên Zalo, người bán có cần phải có giấy đăng ký phép kinh doanh không?",
    content:
      "Không có giấy phép kinh doanh, người bán vẫn có thể đăng ký bán hàng bằng Zalo cá nhân của mình. Tuy nhiên nếu có giấy phép kinh doanh, người bán có thể đăng ký sử dụng Zalo Official Account với nhiều tính năng quảng cáo và CSKH hơn.",
  },
  {
    type: 3,
    title: "Zalo Official Account là gì?",
    content:
      "Zalo Official Account là tài khoản dành riêng cho Doanh nghiệp (có giấy phép đăng ký kinh doanh), tạo ra môi trường thuận lợi để tương tác với khách hàng, từ đó tăng cường nhận diện thương hiệu và sự tin tưởng của khách hàng trên nền tảng Zalo",
  },
  {
    type: 1,
    title: "Các bước đăng ký bán hàng trên Zalo thế nào?",
    content: [
      (<>Truy cập <a style={{color:"#0068FF"}} href="https://social.zalopay.vn/spa/v2/seller-hub/seller/register">Link</a> và điền các thông tin được yêu cầu (Tên cửa hàng, địa chỉ, SĐT, Giấy phép Đăng ký kinh doanh, CMND/CCCD,...)</>),
      "Hoàn tất thông tin và đợi nhân viên ZaloPay duyệt cửa hàng",
      "Sau khi được thông báo duyệt hồ sơ thành công, bắt đầu bán hàng!",
    ],
  },
  {
    type: 2,
    title: "Bán hàng trên Zalo giao hàng bằng hình thức nào?",
    main: "Người bán có hai lựa chọn giao hàng:",
    content: ["Tự giao hàng", "Giao hàng bằng đối tác Ahamove"],
  },
];

export const CHAT_ZALOPAY_SHOP = {
  title: "Bạn cần hỗ trợ thêm?",
  content: "Chat với ZaloPay Shop",
};

export const BANNER_TEXT = {
  zalopay: "ZaloPay Shop",
  title: (
    <>
      Chat hiệu quả <br /> Chốt đơn dễ dàng
    </>
  ),
  content:
    "Mở gian hàng tích hợp vừa chat vừa mua bán nhanh chóng ngay trên Zalo",
  button: "Bắt đầu ngay",
};

export const INTRO_TEXT = {
  title: "ZaloPay cung cấp giải pháp bán hàng phù hợp",
  content:
    "ZaloPay Shop với nhiều tính năng độc đáo, được xây dựng chuyên biệt để thỏa mãn mọi nhu cầu mua bán trong cuộc sống và nhu cầu kinh doanh",
  session1: {
    title: "Zalo Official Account",
    content:
      "Tài khoản dành riêng cho doanh nghiệp với giải pháp chăm sóc khách hàng vượt trội cùng nhiều tiện ích hàng đầu",
  },
  session2: {
    title: "Zalo Chat",
    content:
      "Dành riêng cho khách hàng cá nhân  với mong muốn chăm sóc khách hàng, xây dựng thương hiệu với ít chi phí bỏ ra",
  },
};
