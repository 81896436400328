import ItemList from 'components/ItemList'
import React from 'react'
import { QUESTIONS } from 'utils/const'
import './style.scss'



const Knowledge = () => {
  return (
    <section className='p-knowledge grid sm:mt-20'>
      <h3 className='text-2xl text-center font-bold sm:mb-11 sm:text-4xl'>
        Câu hỏi thường gặp
      </h3>
      <div className='flex flex-col sm:w-[70%] sm:m-[auto]'>
        {
          QUESTIONS.map((question, i) => <ItemList
            key={question.title}
            main={question.main} title={question.title} 
            type={question.type} content={question.content} />)
        }
      </div>
    </section>
  )
}

export default Knowledge