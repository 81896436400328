import React from 'react'
import logo from "assets/images/landing/logo1.svg";

import './style.scss'

const PCHeader = () => {
  return (
    <div className="header-main" >
      <div style={{ paddingLeft: '5vw' }}>
        <img src={logo} style={{ height: '40px' }} alt="logo" />
      </div>
    </div>
  )
}

export default PCHeader