import ChatZaloPayShop from 'components/ChatZaloPayShop'
import ZaloPayFooter from 'components/ZaloPayFooter'
import React from 'react'

const Footer = () => {
  return (
    <section className='mt-20 sm:mt-0'>
      <ChatZaloPayShop/>
      <ZaloPayFooter/>
    </section>
  )
}

export default Footer