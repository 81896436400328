import ZaloPayHeader from 'components/ZaloPayHeader'
import React from 'react'
import Banner from './components/Banner'
import Benefit from './components/Benefit'
import Footer from './components/Footer'
import Introduction from './components/Introduction'
import Knowledge from './components/Knowledge'

const SellerHub = () => {
  return (
    <main>
      <ZaloPayHeader/>
      <Banner/>
      <Introduction/>
      <Benefit/>
      <Knowledge/>
      <Footer/>
    </main>
  )
}

export default SellerHub