const config = {
  dev: {
    DOMAIN_WEB: "https://socialdev.zalopay.vn/spa/v2/oa-sales-channel",
    DOMAIN_ZMP: "https://zalo.me/s/",
    ENV_ZMP: "&env=TESTING&zpi_env=DEVELOPMENT",
    // DOMAIN_WEB: "https://zlpqc-zbs-api-gateway.zalopay.vn/v2/oa-shop/share"
    //https://zlpqc-zbs-api-gateway.zalopay.vn/v2/oa-shop/share?id=1&type=ST_StoreID
  },
  prod: {
    DOMAIN_WEB: "https://social.zalopay.vn/spa/v2/oa-sales-channel",
    DOMAIN_ZMP: "https://zalo.me/s/",
    ENV_ZMP: "",
  },
  ZMP_ZPI_ID: "3888182390069527250",
};
export default config;
