import configs from "configs";
import { useState, useEffect } from "react";
import Step from "./components/Step";
import "./styles.scss";

import hoangYenBanner from "assets/images/hoang-yen/banner.png";
import chaoCaLogo from "assets/images/hoang-yen/chao-ca.png";
import trongComLogo from "assets/images/hoang-yen/trong-com.png";
import hoangYenBLogo from "assets/images/hoang-yen/hoang-yen-b.png";
import hoangYenBuLogo from "assets/images/hoang-yen/hoang-yen-bu.png";
import hoangYenLogo from "assets/images/hoang-yen/hoang-yen.png";
import step1Logo from "assets/images/hoang-yen/step1-be.png";
import step2Logo from "assets/images/hoang-yen/step2-be.png";
import step3Logo from "assets/images/hoang-yen/step3-be.png";
import step4Logo from "assets/images/hoang-yen/step4-be.png";

const env = "prod";

function redirectUrl(url) {
  if (window.zlpSdk) {
    window.zlpSdk.Navigator.navigateTo({ url });
  }
  window.location.href = url;
}
function onClickButtonChaoCa() {
  const appID = 2950;
  const url = `${configs[env].DOMAIN_WEB}/store/${appID}`;
  redirectUrl(url);
}
function onClickButtonHoangYen() {
  const appID = 2946;
  const url = `${configs[env].DOMAIN_WEB}/store/${appID}`;
  redirectUrl(url);
}
function onClickButtonHoangYenBuffet() {
  const appID = 2951;
  const url = `${configs[env].DOMAIN_WEB}/store/${appID}`;
  redirectUrl(url);
}

const HoangYenMiddlePage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const mpSDKScript = document.createElement('script');
    mpSDKScript.onload = () => {
      setIsLoading(false);
    };
    mpSDKScript.src = 'https://mp.zalopay.vn/v1/mp/sdk/js-sdk.js?platform=iframe';
    mpSDKScript.async = true;
    document.head.appendChild(mpSDKScript);
  }, [])

  return (
    <main className="hoang-yen-page flex flex-col">
      <div className="hy-banner w-full">
        <img className="w-full" src={hoangYenBanner} alt="Hoàng Yến" />
      </div>
      <section className="-mt-[17px] px-4 pt-5 pb-12 rounded-t-2xl w-full bg-white relative shadow-[2px_0px_6px_0px_rgba(0,31,62,0.15)]">
        <div className="mb-4">
          <h2 className="font-bold text-lg text-center mb-3">
            Đặt các món ngon từ các nhà hàng thuộc chuỗi Hoàng Yến ngay trên
            Zalo
          </h2>
          <p className="text-center text-[#66798B] text-base leading-5">
            Thực hiện 4 bước
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <Step
            data={{
              logo: step1Logo,
              count: "Bước 1",
              title: "Chọn nhà hàng",
              description: "Chọn nhà hàng yêu thích để đặt món",
            }}
          >
            <>
              <div className="mb-3">
                <p className="mb-1">Món ngon đa dạng-Bắc Trung-Nam</p>
                <div className="wrapper-order flex rounded-lg p-3 items-center justify-between">
                  <div className="flex gap-4">
                    <img className="h-[48px]" src={chaoCaLogo} alt="Chảo Cá" />
                    <img
                      className="h-[48px]"
                      src={trongComLogo}
                      alt="Trống Cơm"
                    />
                  </div>
                  <button
                    aria-label="Cháo Cá & Trống Cơm"
                    className="bg-[#0068FF] min-w-[70px] text-white font-bold text-xs px-3 py-2 rounded-md active:bg-[#99C3FF] transition-[background-color] duration-200"
                    onClick={() => !isLoading && onClickButtonChaoCa()}
                  >
                    Đặt món
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <p className="mb-1">Tinh hoa ẩm thực Việt</p>
                <div className="wrapper-order flex rounded-lg p-3 items-center justify-between">
                  <div className="flex gap-4">
                    <img
                      className="h-[48px]"
                      src={hoangYenLogo}
                      alt="Hoàng Yến"
                    />
                  </div>
                  <button
                    aria-label="Hoàng Yến"
                    className="bg-[#0068FF] min-w-[70px] text-white font-bold text-xs px-3 py-2 rounded-md active:bg-[#99C3FF] transition-[background-color] duration-200"
                    onClick={() => !isLoading && onClickButtonHoangYen()}
                  >
                    Đặt món
                  </button>
                </div>
              </div>
              <div>
                <p className="mb-1">Buffet cao cấp</p>
                <div className="wrapper-order flex rounded-lg p-3 items-center justify-between">
                  <div className="flex gap-4">
                    <img
                      className="h-[48px]"
                      src={hoangYenBuLogo}
                      alt="Hoàng Yến Buffet"
                    />
                    <img
                      className="h-[48px]"
                      src={hoangYenBLogo}
                      alt="Hoàng Yến Buffet"
                    />
                  </div>
                  <button
                    aria-label="Hoàng Yến Buffet"
                    className="bg-[#0068FF] min-w-[70px] text-white font-bold text-xs px-3 py-2 rounded-md active:bg-[#99C3FF] transition-[background-color] duration-200"
                    onClick={() => !isLoading && onClickButtonHoangYenBuffet()}
                  >
                    Đặt món
                  </button>
                </div>
              </div>
            </>
          </Step>
          <Step
            data={{
              logo: step2Logo,
              count: "Bước 2",
              title: "Chọn món",
              description: "Chọn món yêu thích và thêm vào giỏ hàng",
            }}
          />
          <Step
            data={{
              logo: step3Logo,
              count: "Bước 3",
              title: "Thanh toán",
              description: "Kiểm tra thông tin đơn hàng và thanh toán",
            }}
          />
          <Step
            data={{
              logo: step4Logo,
              count: "Bước 4",
              title: "Nhận hàng",
              description:
                "Nhân viên giao hàng sẽ liên hệ khi đơn hàng sẵn sàng giao đến bạn",
            }}
          />
        </div>
      </section>
    </main>
  );
};

export default HoangYenMiddlePage;
