import React from 'react'
import { ReactComponent as ArrowDown } from 'assets/images/landing/arrow-down.svg'
import './style.scss'
import { useState } from 'react'

const renderContent = (content, type, main) => {
  switch (type) {
    case 1:
      return (<ul className='list-decimal pl-4'>
        {content.map(e => <li key={e}>{e}</li>)}
      </ul>)
    case 2:
      return (
        <div>
          {main !== "" ? <p>{main}</p> : <></>}
          <ul className='list-type-insite'>
            {content.map(e => <li key={e}>{e}</li>)}
          </ul>
        </div>
      )
    default:
      return (<ul>
        {content}
      </ul>)
  }
}

const ItemList = ({ title, content, type, main = "" }) => {
  const [active, setActive] = useState(false)
  const onClick = () => {
    setActive(!active)
  }
  return (
    <>
      <div className='cursor-pointer flex justify-between items-center p-item' onClick={onClick}>
        <h5 className={`text-base font-normal sm:text-lg ${active?'text-[#0068FF]':''}`}>
          {title}
        </h5>
        <div className={`arrow-btc ${active?'arrow-btc-active':''}`}>
          <ArrowDown />
        </div>
      </div>
      <div className={`item-content ${active?'show-content':''}`}>
        <div className={`bg-[#F5F9FF] flex flex-col text-sm text-[#334C65] text-item-title sm:text-base`}>
          {renderContent(content, type, main)}
        </div>
      </div>
    </>
  )
}

export default ItemList