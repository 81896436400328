import React from 'react'
import { INTRO_TEXT } from 'utils/const'
import demo1 from 'assets/images/landing/demo1.png'
import demo2 from 'assets/images/landing/demo2.png'
import bgDemo1 from 'assets/images/landing/bg-demo1.png'
import bgDemo2 from 'assets/images/landing/bg-demo2.png'
import toroP1 from 'assets/images/landing/toro-p1.png'
import toroP2 from 'assets/images/landing/toro-p2.png'
import './style.scss'

const Introduction = () => {
  return (
    <section className='pt-12 pl-4 pr-4 pb-8 lg:pt-[90px] lg:pb-[90px]'>
      <h3 className='font-semibold text-2xl text-center pl-3 pr-3 leading-9 sm:text-4xl'>
        {INTRO_TEXT.title}
      </h3>
      <p className='hidden mt-4 text-center sm:block w-[60%] m-[auto] text-[#66798B]'>
        {INTRO_TEXT.content}
      </p>
      <div className='mt-6 sm:mt-[50px] sm:flex lg:w-[80%] sm:m-[auto]'>
        <div className='bg-phone flex items-center sm:items-start sm:pl-4 lg:pl-8 lg:mr-3' style={{ backgroundImage: `url(${bgDemo1})` }}>
          <div className='shadow-phone bg-phone1 contents'>
            <img src={demo1} alt="phone-demo" className='w-[150px] sm:w-[200px]'/>
          </div>
          <div>
            <div className='ml-4 sm:ml-[30px] xs:mt-8'>
              <h5 className='font-bold text-lg mb-2'>
                {INTRO_TEXT.session1.title}
              </h5>
              <p className='text-sm leading-[22px] text-[#66798B]'>{INTRO_TEXT.session1.content}</p>
            </div>
            <img src={toroP1} alt="toro-p1" width={72} className="sm:hidden pt-8"/>
          </div>
        </div>
        <div className='bg-phone bg-phone2 flex items-center sm:flex-row-reverse sm:items-start sm:pl-4 lg:pl-8 lg:ml-3' style={{ backgroundImage: `url(${bgDemo2})` }}>
          <div>
            <div className='pr-4 sm:ml-[30px] xs:mt-8'>
              <h5 className='font-bold text-lg'>
                {INTRO_TEXT.session2.title}
              </h5>
              <p className='text-sm leading-[22px] text-[#66798B]'>{INTRO_TEXT.session2.content}</p>
            </div>
            <div className='flex justify-end sm:hidden'>
              <img src={toroP2} alt="toro-p2" width={72} className="pt-8"/>
            </div>
          </div>
          <div className='shadow-phone2 contents'>
            <img src={demo2} alt="phone-demo2" className='w-[150px] sm:w-[200px] sm:h-[340px]' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Introduction