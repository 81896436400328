import React from 'react'
import { ReactComponent as ChatZaloPay } from 'assets/images/landing/chat-zalopay.svg'
import { CHAT_ZALOPAY_SHOP } from 'utils/const'

const ChatZaloPayShop = () => {
  const onClickChat = () => {
    window.location.href='https://zalo.me/4211982193015940485'
  }
  return (
    <div className='bottom-[0] w-[100%] shadow-[0px_2px_12px_rgba(0,31,62,0.05)] bg-white lg:shadow-none sm:flex sm:items-center sm:flex-col sm:relative lg:mt-[50px]'>
      <div className='p-4 flex items-center cursor-pointer' onClick={onClickChat}>
        <div className='mr-3'>
          <ChatZaloPay className='sm:w-[72px] sm:h-[72px]'/>
        </div>
        <div>
          <p className='text-sm sm:text-lg'>{CHAT_ZALOPAY_SHOP.title}</p>
          <p className='text-sm mt-1 font-bold text-[#0068FF] sm:text-base'>{CHAT_ZALOPAY_SHOP.content}</p>
        </div>
      </div>
    </div>
  )
}

export default ChatZaloPayShop