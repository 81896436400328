import React from 'react'
import { ReactComponent as CallCS } from 'assets/images/landing/callcs.svg'
import { ReactComponent as PhoneNumber } from 'assets/images/landing/phone-number.svg'
import { ReactComponent as Safety } from 'assets/images/landing/absolute-safety.svg'
import { ReactComponent as Free } from 'assets/images/landing/free.svg'
import  toro  from 'assets/images/landing/toro-wow.png'
import bgBenefit from 'assets/images/landing/bg-benefit.jpg'
import { BENEFIT_SECTION } from 'utils/const'
import Card from 'components/Card'
import './style.scss'
import Button from 'components/Button'

BENEFIT_SECTION.list.map((e, i) => {
  switch (i) {
    case 0:
      e.image = <CallCS />
      break;
    case 1:
      e.image = <PhoneNumber />
      break;
    case 2:
      e.image = <Safety />
      break;
    case 3:
      e.image = <Free />
      break;
    default:
      break;
  }
  return null
})

const Benefit = () => {
  
  return (
    <section className="bg-benefit" style={{backgroundImage:`url('${bgBenefit}')`}}>
      <div className="flex justify-between items-center sm:flex-col">
        <h3 className="text-2xl leading-8 font-bold w-[70%] sm:text-white sm:text-center sm:w-[100%] sm:text-4xl">
          {BENEFIT_SECTION.title}
        </h3>
        <p className='hidden text-center sm:block sm:mt-4 sm:w-[70%] lg:w-[50%] sm:text-white'>
          ZaloPay hỗ trợ các giải pháp cho các doanh nghiệp kinh doanh online và offline dưới nhiều phương thức tiện dụng
        </p>
        <div className='w-[72px] h-[72px] sm:hidden'>
          <img src={toro} alt="" className='w-[72px] h-[72px]'/>
        </div>
      </div>
      <div className='grid gap-3 mt-4 mb-6 sm:grid-cols-2 sm:pr-4 sm:pl-4 lg:pl-40 lg:pr-40 xl:pl-60 xl:pr-60 2xl:pl-80 2xl:pr-80'>
        {
          BENEFIT_SECTION.list.map(item => <Card key={item.title} title={item.title} image={item.image} content={item.content} />)
        }
      </div>
      <div className='sm:hidden'>
        <Button text={"Đăng ký bán hàng trên ZaloPay ngay"} />
      </div>
    </section>
  )
}

export default Benefit