import React from 'react'
import './style.scss'

const Card = ({image,title,content}) => {
  return (
    <div className='p-4 bg-white rounded-lg shadow-card flex'>
      <div className='mr-4'>
        {image}
      </div>
      <div>
        <h5 className='font-bold text-lg'>
          {title}
        </h5>
        <p className='text-[#66798B] text-sm'>{content}</p>
      </div>
    </div>
  )
}

export default Card