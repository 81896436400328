import React, { useEffect, useState } from "react";
import { checkIsBigCAppID, getEnv } from "utils/utils";
import { isAndroid } from "react-device-detect";
import configs from "configs";

import "./styles.scss";
import PCHeader from "./components/PCHeader";

import PCBody from "./components/PCBody";
import MobileBody from "./components/MobileBody";
// import { useParams } from "react-router-dom";

const isMobile = window.matchMedia("(pointer:coarse)").matches;

const BufferPage = () => {
  const [appType, setAppType] = useState("");
  const [redirectURL, setRedirectURL] = useState("");
  // let { storeCode } = useParams();

  useEffect(() => {
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const query = {};
    const passKeys = [
      "utm_source", "utm_medium", "utm_campaign",
      "adTracking", "adtracking", "debug"
    ];
    let isBigC = false,
      zmpSearch = "";
    let url = '';
    // url = configs[getEnv()].DOMAIN_WEB + window.location.pathname.replace("/store/" + storeCode, "");
    let appID = "923";

    if (search) {
      (search.split("?")[1] || "").split("&").map((q) => {
        const e = q.split("=");
        query[e[0]] = e[1];
        return q;
      });
    }

    if(query.rid) { // Redirect to Reservation
      setAppType("Reservation");
      url = configs[getEnv()].RESERVATION_DOMAIN_WEB + window.location.pathname.replace("/landing", "");
      document.title = "Đặt chỗ trên ZaloPay";
      url = `${url}/home/${query.rid}`;
    } else { // Redirect to OSC
      setAppType("OSC");
      url = configs[getEnv()].DOMAIN_WEB + window.location.pathname.replace("/landing", "");
      document.title = "Ghé thăm cửa hàng trên ZaloPay";
      if (query.aid) {
        appID = query.aid;
      }
      isBigC = checkIsBigCAppID(appID);
      if (query.cate) {
        url += `/category/${appID}/${query.cate}?`;
        zmpSearch = `?cate=${query.cate}`;
      } else if (query.sku) {
        url += `/item/${appID}/${query.sku}?`;
        zmpSearch = `?sku=${query.sku}`;
      } else if (query.sid) {
        url += `/home/${appID}?sid=`;
        if (isBigC) {
          url += "0";
        } else {
          let tmp = `${query.sid}`;
          const sid = tmp.replace("%3F", "%26");
  
          url += sid;
          zmpSearch = `?sid=${sid}&home=${appID}`;
        }
      } else if (query.barcode && ['category', 'item'].includes(query.redirect ?? '')) {
        url += `/redirect/${query.redirect}/${appID}/${query.barcode}?`;
      } else {
        url += `/store/${appID}?`;
      }
    }
    const newUrl = new URL(url);
    // if the url has zmpid => link map with zalo to open zmp
    if (searchParams.get("id") && searchParams.get("id") !== "") {
      // Change url to examp: https://zalo.me/s/:id/oa-sales-channel/home/123?sid=1232_122&sku=12
      url =
        configs[getEnv()].DOMAIN_ZMP +
        searchParams.get("id") +
        newUrl.pathname.replace("/spa/v2", "") +
        `${
          searchParams.get("id") === configs.ZMP_ZPI_ID // if the id is zpi app, will not add zmp search
            ? "?"
            : zmpSearch
        }` +
        configs[getEnv()].ENV_ZMP;
      console.log({ url }, newUrl);
    }

    for (const element of passKeys) {
      const k = element;
      if (query[k]) {
        url += `&${k}=${query[k]}`;
      }
    }

    setRedirectURL(url);
    // Auto redirect on mobile
    if (isMobile) {
      if (isAndroid) {
        window.location.replace(
          `https://zalo.me/zalopay?redirect_url=${encodeURIComponent(url)}`
        );
        return;
      }
      window.location.replace(
        `zalo://qr/link/zalopay?redirect_url=${encodeURIComponent(url)}`
      );
      setTimeout(() => {
        if (isAndroid) {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=com.zing.zalo&hl=vi"
          );
        } else {
          window.location.replace(
            "https://apps.apple.com/us/app/zalo/id579523206"
          );
        }
      }, 3000);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("resize", () => {
      const it = document.querySelector(".landing-page");
      it.classList = "landing-page ";
      it.classList += this.getBackgroundClass();
    });
  }, []);
  const handleClick = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
    handleRedirect(redirectURL);
  };
  const handleDownloadAppClick = (event) => {
    if (isAndroid) {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.zing.zalo&hl=vi"
      );
      return;
    }
    window.location.replace("https://apps.apple.com/us/app/zalo/id579523206");
  };
  const handleRedirect = (url) => {
    if (isMobile) {
      if (isAndroid) {
        window.location.replace(
          `https://zalo.me/zalopay?redirect_url=${encodeURIComponent(url)}`
        );
        return;
      }
      window.location.replace(
        `zalo://qr/link/zalopay?redirect_url=${encodeURIComponent(url)}`
      );
    } else {
      window.location.replace(url);
    }
  };

  const renderMobilePage = () => {
    return (
      <div className="landing-page-main">
        <MobileBody
          handleClick={handleClick}
          handleDownloadAppClick={handleDownloadAppClick}
        />
      </div>
    );
  };

  const renderPcPage = () => {
    return (
      <div className="landing-page-main">
        <PCHeader />
        <PCBody redirectURL={redirectURL} appType={appType} />
      </div >
    )
  }

  if (isMobile) {
    return renderMobilePage();
  } else {
    return renderPcPage();
  }
};

export default BufferPage;
