import React from 'react'
import { ReactComponent as Logo } from 'assets/images/landing/logo.svg'
import { BANNER_TEXT } from 'utils/const'

const ZaloPayHeader = () => {
  return (
    <div className='flex pl-5 pr-5 pt-3 pb-3 items-center bg-[#F5F9FF] sticky top-[0] z-10 sm:relative sm:pl-8 sm:pt-6 sm:pb-6 sm:bg-[#EFF5FF]'>
      <Logo className='sm:w-[72px] sm:h-[72px]'/>
      <h1 className='ml-3 font-bold sm:text-2xl'>
        {BANNER_TEXT.zalopay}
      </h1>
    </div>
  )
}

export default ZaloPayHeader