import React from 'react'
import './style.scss'

const Button = ({text,type=1}) => {
  const onClick = () => {
    window.location.href = 'https://social.zalopay.vn/spa/v2/oa-sales-channel/seller/register'
  }
  return (
    <button onClick={onClick} className={`bg-[#0068FF] cursor-pointer text-white ${type===1?'p-button':'p-button-xs'} font-semibold text-base rounded-lg w-[100%] button-active`}>
      {text}
    </button>
  )
}

export default Button