import React from "react";
import logoZl from "assets/images/landing/logo-zl.png";
import logoZlp from "assets/images/landing/logo-zlp.png";
import './style.scss'

const MobileBody = ({handleClick, handleDownloadAppClick}) => {
  const renderLogo = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ margin: '10px', padding: '15px', background: '#F2F4F5', display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logoZl} style={{ height: '36px' }} alt="logoZl" />
        </div>
        <div style={{ margin: '10px', padding: '15px', background: '#F2F4F5', display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logoZlp} style={{ height: '36px' }} alt="logoZlp" />
        </div>
      </div>
    )
  }
  const renderGuide = (text) => {
    return (
      <div> 
        <p style={{ textAlign: 'center', margin: '30px', fontWeight: 400, fontSize: 16, lineHeight: '38px' }}> {text} </p>
      </div>
    )
  }
  const renderBtn = (text) => {
    return (
      <div className="btn" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleClick}>
        <p> {text} </p>
      </div>
    )
  }
  const renderGuideDownload = (handleDownloadAppClick) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '16px', flexDirection: 'row' }}>
        <div >
          <p style={{ lineHeight: '44px', fontSize: '14px' }}>Chưa có ứng dụng Zalo? </p>
        </div>
        <div onClick={handleDownloadAppClick}>
          <p style={{ paddingLeft: '5px', lineHeight: '44px', fontSize: '14px', color: '#0068FF' }}> Tải ngay</p>
        </div>
    </div>
    )
  }
  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} className={`landing-page`}>
      <div style={{ background: '#FFFFFF', display: 'flex', borderRadius: '8px', flexDirection: 'column' }}>
        {renderLogo()}
        {renderGuide("Mở Zalo để vào cửa hàng")}
        {renderBtn("Mở Zalo")}
        {renderGuideDownload(handleDownloadAppClick)}
      </div>
    </div >
  )
}

export default MobileBody