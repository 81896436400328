export const checkIsBigCAppID = (appID = "") => {
  const flowBigCAppID = [
    "bigC",
    "923", // 'Go! An Lạc', dev/sandbox BigC
    "994", // 'Big C - Miền Đông'
    "995", // 'Big C - Bình Dương'
    "996", // 'Go! Dĩ An'
    "997", // 'Big C - Thăng Long'
    "998", // 'Big C - Long Biên'
    "999", // 'Go! Nguyễn Thị Thập'
    "1000", // 'Big C - Trường Chinh'
    "1001", // 'Big C - Phú Thạnh'
    "1002", // 'Big C - Gò Vấp'
    "1003", // 'Big C - Đồng Nai'
    "1004", // 'Big C - Tân Hiệp'
    "1005", // 'Big C - Hải Dương'
    "1027", // 'Go! Trà Vinh'
    "1028", // 'Big C - Mê Linh'
    "1029", // 'Go! Hải Phòng'
    "1030", // 'Go! Đà Nẵng'
    "1031", // 'Big C - Việt Trì'
    "1032", // 'Big C - Ninh Bình'
    "1033", // 'Go! Hạ Long'
    "1034", // 'Go! Bắc Giang'
    "1035", // 'Go! Quảng Ngãi'
    "1036", // 'Go! Huế'
    "1037", // 'Go! Đà Lạt'
    "1038", // 'Big C - Quy Nhơn'
    "1039", // 'Go! Nha Trang'
    "1040", // 'Go! Buôn Mê Thuột'
    "1041", // 'Big C - Vinh'
    "1042", // 'Go! Nam Định'
    "1043", // 'Big C - Thanh Hóa'
    "1044", // 'Go! Vĩnh Phúc'
    "1045", // 'Go! Cần Thơ'
    "1046", // 'Go! Mỹ Tho'
    "1047", // 'Go! Bến Tre'
    "1262", // 'Go! Thái Nguyên'
    "1263", // 'Go! Bà Rịa'
    "1398", // 'Go! Thái Bình'
  ];
  return flowBigCAppID.indexOf(appID) !== -1;
};

export const getEnv = () => {
  if (window.location.href.includes("dev")) {
    return "dev";
  }
  return "prod";
};
