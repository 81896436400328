import React from 'react'
import bg from 'assets/images/landing/bg-footer.jpg'
import { ReactComponent as ZaloPayLogo } from 'assets/images/landing/zlp-logo.svg'
import './style.scss'

const ZaloPayFooter = () => {
  return (
    <div className='hidden sm:block pt-12 pb-9 zlp-footer' style={{ backgroundImage: `url('${bg}')` }}>
      <div className='w-[70%] m-[auto]'>
        <div className='flex justify-between items-center pb-6  zlp-footer-line'>
          <ZaloPayLogo />
          <p className='text-xs text-white'>© 2020 - Bản quyền của ZaloPay</p>
        </div>
        <div className='mt-6 text-white'>
          <h5 className='font-bold mb-2'>CÔNG TY CỔ PHẦN ZION</h5>
          <p className='text-sm text-[#E6E9EC]'>Địa chỉ: Z06 Đường số 13, Phường Tân Thuận Đông, Quận 7, Tp. Hồ Chí Minh, Việt Nam.</p>
          <p className='text-sm text-[#E6E9EC]'>
            Giấy chứng nhận đăng ký kinh doanh số: 0101659783, đăng ký thay đổi lần thứ 30, ngày 22 tháng 01 năm 2020 do Sở kế hoạch và đầu tư <br />Thành phố Hồ Chí Minh cấp.</p>
        </div>
      </div>
    </div>
  )
}

export default ZaloPayFooter