import React from 'react'
import Button from 'components/Button'
import { BANNER_TEXT } from 'utils/const'
import toroShop from 'assets/images/landing/toro-shop.png'
import './style.scss'

const Banner = () => {
  return (
    <section>
      <div className='pt-3 pb-6 pl-6 pr-6 wrapper-banner sm:pt-[72px] sm:pb-[106px] sm:pl-[100px] lg:pl-[195px] relative'>
        <div className='hidden absolute top-0 sm:top-[20%] sm:block md:top-[10%] sm:left-[60%] lg:top-[0] lg:left-[55%]'>
          <img src={toroShop} alt="" className='sm:w-[100%] max-w-[500px]'/>
        </div>
        <h3 className='font-semibold text-lg sm:text-3xl lg:text-[40px] lg:leading-[56px]'>
          {BANNER_TEXT.title}
        </h3>
        <p className='text-[#66798B] text-sm w-[70%] sm:w-[60%] sm:text-lg lg:text-[24px] lg:leading-[36px] sm:text-[#334C65] sm:max-w-[450px] lg:max-w-[550px] sm:mt-3'>
          {BANNER_TEXT.content}
        </p>
        <div className='w-[40%] mt-4 sm:max-w-[240px]'>
          <Button text={BANNER_TEXT.button} type={2}/>
        </div>
      </div>
    </section>
  )
}

export default Banner